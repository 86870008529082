import React from "react";
import styles from "../PersonalDetails/PersonalDetails.module.css";
import InputControl from "../../InputControl/InputControl.js";
import { useAgentDetails } from "../AgentProfileContext.js";

const ClientInformation = () => {
  const { clientInformation, setClientInformation } = useAgentDetails();

  return (
    <div className={styles.userDetails}>
      <div className={styles.headingContainer}>
        <h6>Client Information</h6>
      </div>
      <div className={styles.detail}>
        <div className={styles.row}>
          <InputControl
            label="Client Testimonials/Reviews"
            type="text"
            placeholder="Enter client testimonials or reviews, comma-separated"
            value={clientInformation.reviews.join(", ")}
            onChange={(e) =>
              setClientInformation({
                ...clientInformation,
                reviews: e.target.value.split(",").map((review) => review.trim()),
              })
            }
          />
        </div>

        <div className={styles.row}>
          <InputControl
            label="Availability"
            type="text"
            placeholder="Enter available times/days for meetings (e.g., Monday 9AM-5PM)"
            value={clientInformation.availability}
            onChange={(e) =>
              setClientInformation({
                ...clientInformation,
                availability: e.target.value,
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ClientInformation;
