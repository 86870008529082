import React from "react";
import styles from "../PersonalDetails/PersonalDetails.module.css";
import InputControl from "../../InputControl/InputControl.js";
import { useAgentDetails } from "../AgentProfileContext.js";

const AdditionalAttributes = () => {
  const { additionalAttributes, setAdditionalAttributes } = useAgentDetails();

  return (
    <div className={styles.userDetails}>
      <div className={styles.headingContainer}>
        <h6>Additional Attributes</h6>
      </div>
      <div className={styles.detail}>
        <div className={styles.row}>
          <InputControl
            label="Languages Spoken"
            type="text"
            placeholder="Enter languages spoken (comma-separated)"
            value={additionalAttributes.languages.join(", ")}
            onChange={(e) =>
              setAdditionalAttributes({
                ...additionalAttributes,
                languages: e.target.value.split(",").map((lang) => lang.trim()),
              })
            }
          />
        </div>

        <div className={styles.row}>
          <InputControl
            label="Awards & Recognitions"
            type="text"
            placeholder="Enter awards & recognitions (comma-separated)"
            value={additionalAttributes.awards.join(", ")}
            onChange={(e) =>
              setAdditionalAttributes({
                ...additionalAttributes,
                awards: e.target.value.split(",").map((award) => award.trim()),
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default AdditionalAttributes;
