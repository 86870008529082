import React from "react";
import styles from "../PersonalDetails/PersonalDetails.module.css";
import InputControl from "../../InputControl/InputControl.js";
import { useAgentDetails } from "../AgentProfileContext.js";

const ProfessionalInformation = () => {
  const { professionalInformation, setProfessionalInformation } = useAgentDetails();

  return (
    <div className={styles.userDetails}>
      <div className={styles.headingContainer}>
        <h6>Professional Information</h6>
      </div>
      <div className={styles.detail}>
        <div className={styles.row}>
          <InputControl
            label="Years of Experience"
            type="number"
            imp="true"
            placeholder="Enter years of experience"
            value={professionalInformation.yearsOfExperience}
            onChange={(e) =>
              setProfessionalInformation({
                ...professionalInformation,
                yearsOfExperience: e.target.value,
              })
            }
          />
        </div>

        <div className={styles.row}>
          <InputControl
            label="Specializations"
            type="text"
            imp="true"
            placeholder="Enter specializations (e.g., Residential, Commercial)"
            value={professionalInformation.specializations.join(", ")}
            onChange={(e) =>
              setProfessionalInformation({
                ...professionalInformation,
                specializations: e.target.value.split(",").map((s) => s.trim()),
              })
            }
          />
        </div>

        <div className={styles.row}>
          <InputControl
            label="Geographic Areas Covered"
            type="text"
            placeholder="Enter areas covered (City, Region, Zip Codes)"
            value={professionalInformation.geographicAreas}
            onChange={(e) =>
              setProfessionalInformation({
                ...professionalInformation,
                geographicAreas: e.target.value,
              })
            }
          />
        </div>

        <div className={styles.row}>
          <InputControl
            label="Certifications & Training"
            type="text"
            placeholder="Enter certifications (e.g., CRS, ABR)"
            value={professionalInformation.certifications.join(", ")}
            onChange={(e) =>
              setProfessionalInformation({
                ...professionalInformation,
                certifications: e.target.value.split(",").map((c) => c.trim()),
              })
            }
          />
        </div>

        <div className={styles.row}>
          <InputControl
            label="Languages Spoken"
            type="text"
            placeholder="Enter languages spoken (if applicable)"
            value={professionalInformation.languagesSpoken.join(", ")}
            onChange={(e) =>
              setProfessionalInformation({
                ...professionalInformation,
                languagesSpoken: e.target.value.split(",").map((l) => l.trim()),
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ProfessionalInformation;
