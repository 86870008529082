import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useAuthContext } from "../context/hook/useAuthContext";
import { useNavigate } from "react-router-dom";
import ListIcon from "@mui/icons-material/List";
import "./header.css";
import { Box, Modal, Typography } from "@mui/material";
import { deleteUser } from "../api-helper/helper";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Header = (props) => {
  const [togglelist, setTogglelist] = useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { user, dispatch } = useAuthContext();

  const handleLogout = () => {
    localStorage.removeItem("user");
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };

  const handleClick = () => setOpen(!open);
  // deleteUser;
  const handleDeleteAccount = () => {
    deleteUser(
      JSON.parse(localStorage.getItem("user")).token,
      user.existingUser ? user.existingUser._id : user.user._id
    )
      .then((res) => {
        localStorage.removeItem("user");
        dispatch({ type: "LOGOUT" });
        navigate("/");
        toast.success("User Deleted", {
          position: "bottom-left",
          theme: "colored",
        });
      })
      .catch((err) => {
        toast.error("Error occurred, please try again in a few minutes!", {
          position: "bottom-left",
          theme: "colored",
        });
      });
  };

  return (
    <header>
      <div className="container flex">
        <Link to="/">
          <div className="logo">
            <img
              style={{ maxWidth: "6rem", maxHeight: "3rem" }}
              src={
                props.configuration && props.configuration.logo
                  ? props.configuration.logo
                  : "http://storage.googleapis.com/a3_visual/apartments/logo-apartment/apartment-logo-2.jpeg"
              }
              alt=""
            />
            {props.configuration && props.configuration.companyId && (
              <p style={{ fontSize: "0.8rem", textAlign: "center" }}>
                {props.configuration.name}
              </p>
            )}
          </div>
        </Link>
        <div className="nav">
          <ul className={togglelist ? "small" : "flex"}>
            {!user && (
              <>
                <li>
                  <HashLink to="/home">Home</HashLink>
                </li>
                <li>
                  <HashLink to="/services">Services</HashLink>
                </li>
                <li>
                  <HashLink to="/about">About</HashLink>
                </li>
                <li>
                  <HashLink to="/achievements">Achievements</HashLink>
                </li>
                <li>
                  <HashLink to="/explore">Explore</HashLink>
                </li>
                <li>
                  <HashLink to="/contact-us">Contact</HashLink>
                </li>
              </>
            )}

            {user && (
              <>
                <li>
                  <HashLink to="/searchlist">Searchlist</HashLink>
                </li>
                <li>
                  <HashLink to="/addproperty">Add Property</HashLink>
                </li>
                <li>
                  <HashLink to="/addprojects">Add Projects</HashLink>
                </li>
                <li>
                  <HashLink to="/myproperties">My Properties</HashLink>
                </li>
                <li>
                  <HashLink to="/register-agent">Register Agent</HashLink>
                </li>
                <li>
                  <HashLink to="/">My Projects</HashLink>
                </li>
                <li>
                  <Link
                    to={`https://property-agent-754f8.web.app/home?id=${
                      user.existingUser ? user.existingUser._id : user.user._id
                    }&token=${user.token}`}
                  >
                    Hosting
                  </Link>
                </li>
              </>
            )}

            {togglelist && (
              <>
                {!user && (
                  <li>
                    <HashLink to="/login">Login</HashLink>
                  </li>
                )}
                {!user && (
                  <li>
                    <HashLink to="/register">Register</HashLink>
                  </li>
                )}
                {user && (
                  <li>
                    <HashLink to="/" onClick={handleLogout}>
                      Logout
                    </HashLink>
                  </li>
                )}
              </>
            )}
          </ul>
        </div>
        <div className="btns flex">
          {!user && (
            <button
              className="btn"
              onClick={() => (window.location.href = "/login")}
            >
              Login
            </button>
          )}
          {!user && (
            <button
              className="btn"
              onClick={() => (window.location.href = "/register")}
            >
              Register
            </button>
          )}
          {user && (
            <>
              <button className="logoutbtn" onClick={handleLogout}>
                Logout
              </button>
              <button className="logoutbtn" onClick={handleClick}>
                Delete Account
              </button>
            </>
          )}
        </div>
        <div className="toggle">
          <button
            onClick={() => setTogglelist(!togglelist)}
            className="togglebtn"
          >
            <ListIcon sx={{ fontSize: "30px" }} />
          </button>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClick}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This action will delete your account and all details related to this
            account.
          </Typography>
          <Box sx={{ mt: "20px", display: "flex", justifyContent: "end" }}>
            <button className="logoutbtn" onClick={handleClick}>
              No
            </button>
            <button className="logoutbtn" onClick={handleDeleteAccount}>
              Yes
            </button>
          </Box>
        </Box>
      </Modal>
    </header>
  );
};

export default Header;
