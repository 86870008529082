import React from "react";
import styles from "../PersonalDetails/PersonalDetails.module.css";
import InputControl from "../../InputControl/InputControl.js";
import { useAgentDetails } from "../AgentProfileContext.js";

const SocialDigitalPresence = () => {
  const { socialDigitalPresence, setSocialDigitalPresence } = useAgentDetails();

  return (
    <div className={styles.userDetails}>
      <div className={styles.headingContainer}>
        <h6>Social and Digital Presence</h6>
      </div>
      <div className={styles.detail}>
        <div className={styles.row}>
          <InputControl
            label="Website"
            type="url"
            placeholder="Enter your personal or agency website"
            value={socialDigitalPresence.website}
            onChange={(e) =>
              setSocialDigitalPresence({
                ...socialDigitalPresence,
                website: e.target.value,
              })
            }
          />
        </div>

        <div className={styles.row}>
          <InputControl
            label="Social Media Links"
            type="text"
            placeholder="Enter social media URLs (LinkedIn, Instagram, Facebook)"
            value={socialDigitalPresence.socialLinks.join(", ")}
            onChange={(e) =>
              setSocialDigitalPresence({
                ...socialDigitalPresence,
                socialLinks: e.target.value
                  .split(",")
                  .map((link) => link.trim()),
              })
            }
          />
        </div>

        <div className={styles.row}>
          <InputControl
            label="Virtual Tour Capability"
            type="checkbox"
            checked={socialDigitalPresence.virtualTour}
            onChange={(e) =>
              setSocialDigitalPresence({
                ...socialDigitalPresence,
                virtualTour: e.target.checked,
              })
            }
          />
          <label className={styles.checkboxLabel}>
            {socialDigitalPresence.virtualTour ? "Enabled" : "Disabled"}
          </label>
        </div>
      </div>
    </div>
  );
};

export default SocialDigitalPresence;
