import React from "react";
import styles from "../PersonalDetails/PersonalDetails.module.css";
import InputControl from "../../InputControl/InputControl.js";
import { useAgentDetails } from "../AgentProfileContext.js";

const PropertyListings = () => {
  const { propertyListings, setPropertyListings } = useAgentDetails();

  return (
    <div className={styles.userDetails}>
      <div className={styles.headingContainer}>
        <h6>Property Listings</h6>
      </div>
      <div className={styles.detail}>
        <div className={styles.row}>
          <InputControl
            label="Active Listings"
            type="text"
            placeholder="Enter URLs of active listings, comma-separated"
            value={propertyListings.activeListings.join(", ")}
            onChange={(e) =>
              setPropertyListings({
                ...propertyListings,
                activeListings: e.target.value.split(",").map((url) => url.trim()),
              })
            }
          />
        </div>

        <div className={styles.row}>
          <InputControl
            label="Sold Listings"
            type="text"
            placeholder="Enter URLs of sold listings, comma-separated"
            value={propertyListings.soldListings.join(", ")}
            onChange={(e) =>
              setPropertyListings({
                ...propertyListings,
                soldListings: e.target.value.split(",").map((url) => url.trim()),
              })
            }
          />
        </div>

        <div className={styles.row}>
          <InputControl
            label="Average Selling Price"
            type="number"
            placeholder="Enter average selling price"
            value={propertyListings.avgSellingPrice}
            onChange={(e) =>
              setPropertyListings({
                ...propertyListings,
                avgSellingPrice: e.target.value,
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default PropertyListings;
