import React from "react";
import styles from "./PersonalDetails.module.css";
import InputControl from "../../InputControl/InputControl.js";
import { useAgentDetails } from "../AgentProfileContext.js";

const PersonalDetails = () => {
  const { personalDetails, setPersonalDetails } = useAgentDetails();

  return (
    <div className={styles.userDetails}>
      <div className={styles.headingContainer}>
        <h6>Personal Details</h6>
      </div>
      <div className={styles.detail}>
        <div className={styles.row}>
          <InputControl
            label="First Name"
            type="text"
            imp="true"
            placeholder="Enter first name"
            value={personalDetails.firstName}
            onChange={(e) =>
              setPersonalDetails({
                ...personalDetails,
                firstName: e.target.value,
              })
            }
          />
          <InputControl
            label="Last Name"
            type="text"
            imp="true"
            placeholder="Enter last name"
            value={personalDetails.lastName}
            onChange={(e) =>
              setPersonalDetails({
                ...personalDetails,
                lastName: e.target.value,
              })
            }
          />
        </div>

        <div className={styles.row}>
          <InputControl
            label="Email"
            type="email"
            imp="true"
            placeholder="Enter email"
            value={personalDetails.email}
            onChange={(e) =>
              setPersonalDetails({ ...personalDetails, email: e.target.value })
            }
          />
          <InputControl
            label="Phone Number"
            type="text" // Changed to 'text' to allow formatting
            imp="true"
            placeholder="Enter phone number"
            value={personalDetails.phoneNumber}
            onChange={(e) =>
              setPersonalDetails({
                ...personalDetails,
                phoneNumber: e.target.value,
              })
            }
          />
          <InputControl
            label="Date of Birth"
            type="date"
            placeholder="Enter date of birth"
            value={personalDetails.dateOfBirth}
            onChange={(e) =>
              setPersonalDetails({
                ...personalDetails,
                dateOfBirth: e.target.value,
              })
            }
          />
        </div>

        <div className={styles.row}>
          <InputControl
            label="License Number"
            type="text"
            imp="true"
            placeholder="Enter license number"
            value={personalDetails.licenseNumber}
            onChange={(e) =>
              setPersonalDetails({
                ...personalDetails,
                licenseNumber: e.target.value,
              })
            }
          />
        </div>

        <div className={styles.row}>
          <InputControl
            label="Address"
            type="text"
            placeholder="Enter address"
            value={personalDetails.address}
            onChange={(e) =>
              setPersonalDetails({
                ...personalDetails,
                address: e.target.value,
              })
            }
          />
        </div>

        <div className={styles.row}>
          <InputControl
            label="City"
            type="text"
            imp="true"
            placeholder="Enter city"
            value={personalDetails.city}
            onChange={(e) =>
              setPersonalDetails({
                ...personalDetails,
                city: e.target.value,
              })
            }
          />
          <InputControl
            label="State"
            type="text"
            imp="true"
            placeholder="Enter state"
            value={personalDetails.state}
            onChange={(e) =>
              setPersonalDetails({
                ...personalDetails,
                state: e.target.value,
              })
            }
          />
          <InputControl
            label="Zip Code"
            type="text" // Changed to 'text' to allow leading zeros
            placeholder="Enter zip code"
            value={personalDetails.zipCode}
            onChange={(e) =>
              setPersonalDetails({
                ...personalDetails,
                zipCode: e.target.value,
              })
            }
          />
        </div>

        <div className={styles.row}>
          <label className={styles.profilePictureLabel}>Profile Picture</label>
          <input
            type="file"
            accept="image/*"
            onChange={(e) =>
              setPersonalDetails({
                ...personalDetails,
                profilePicture: URL.createObjectURL(e.target.files[0]),
              })
            }
          />
          {personalDetails.profilePicture && (
            <img
              src={personalDetails.profilePicture}
              alt="Profile"
              className={styles.profilePicturePreview}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
