import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getApartmentByID } from "../api-helper/apartment";
import { useAuthContext } from "../context/hook/useAuthContext";
import "./style.css";
import Header from "../header/Header";
import AwesomeSlider from "react-awesome-slider";
import {
  MdAttachEmail,
  MdBed,
  MdOutlinePriceCheck,
  MdLocationOn,
} from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { BiArea } from "react-icons/bi";
import { GiFloorHatch } from "react-icons/gi";
import GoogleMapReact from "google-map-react";
import { Helmet } from "react-helmet";

const Apartment = ({ configuration }) => {
  const location = useLocation();
  const { user, dispatch } = useAuthContext();
  const id = location.pathname.substring(11);
  const [property, setProperty] = useState({});

  const getApartment = (token, id) => {
    getApartmentByID(token, id)
      .then((res) => {
        console.log(res);
        setProperty(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (id && user && user.token) {
      getApartment(user.token, id);
    }
  }, [id, user]);

  const [images, setImages] = useState([]);

  useEffect(() => {
    if (property && property.img_links) {
      let data = [];
      for (let image of property.img_links) {
        data.push({ source: image });
      }

      setImages(data);
    }
  }, [property]);

  return (
    <>
      <Header configuration={configuration} />
      <div className="propertyContainer">
        <div className="left">
          <div style={{ margin: "auto" }}>
            <AwesomeSlider media={images} bullets={false}></AwesomeSlider>
          </div>
          <h2 className="name">{property.name}</h2>
          <div className="location">
            <MdLocationOn size={31} />
            <p>{property.location}</p>
          </div>
          <p className="type">{property.type_of_property}</p>
          <div className="properties">
            <div>
              <MdAttachEmail size={21} />
              <p>{property.email}</p>
            </div>
            <div>
              <FaPhoneAlt size={21} />
              <p>{property.phone}</p>
            </div>
            <div>
              <MdOutlinePriceCheck size={21} />
              <p>{property.price}</p>
            </div>
            <div>
              <MdBed size={21} />
              <p>{property.No_of_bedroom}</p>
            </div>
            <div>
              <BiArea size={21} />
              <p>{property.area ? property.area : "NA"}</p>
            </div>
            <div>
              <GiFloorHatch size={21} />
              <p>{property.floor ? property.floor : "NA"}</p>
            </div>
          </div>
        </div>
        <div className="right">
          {property.geoLocation && property.geoLocation.coordinates && (
            <div>
              <div
                style={{
                  zIndex: 100,
                  width: "100%",
                  height: "300px",
                  marginBottom: "10px",
                }}
              >
                <GoogleMapReact
                  bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_API }}
                  center={{
                    lat: property.geoLocation.coordinates[1],
                    lng: property.geoLocation.coordinates[0],
                  }}
                  zoom={11}
                ></GoogleMapReact>
              </div>

              <p>Latitude : {property.geoLocation.coordinates[1]}</p>
              <p>Longitude : {property.geoLocation.coordinates[0]}</p>
            </div>
          )}
        </div>
        <Helmet>
          <title>Property Details - Your Perfect Home</title>
          <meta
            name="description"
            content="Discover detailed information about each property in our listings. Explore photos, amenities, and specifications to find your perfect home. Start your journey to the ideal living space today."
          />
          <meta
            name="keywords"
            content="property details, home specifications, real estate listings, house amenities, housing features, dream home"
          />
        </Helmet>
      </div>
    </>
  );
};

export default Apartment;
