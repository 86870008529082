import React, { createContext, useContext, useState } from "react";

const AgentDetailsContext = createContext();

export const AgentDetailsProvider = ({ children }) => {
  const [personalDetails, setPersonalDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
    licenseNumber: "",
    profilePicture: null,
  });

  const [professionalInformation, setProfessionalInformation] = useState({
    yearsOfExperience: "",
    specializations: [],
    geographicAreas: "",
    certifications: [],
    languagesSpoken: [],
  });

  const [propertyListings, setPropertyListings] = useState({
    activeListings: [],
    soldListings: [],
    avgSellingPrice: "",
  });

  const [clientInformation, setClientInformation] = useState({
    reviews: [],
    availability: "",
  });

  const [socialDigitalPresence, setSocialDigitalPresence] = useState({
    website: "",
    socialLinks: [],
    virtualTour: false,
  });

  const [additionalAttributes, setAdditionalAttributes] = useState({
    languages: [],
    awards: [],
  });

  return (
    <AgentDetailsContext.Provider
      value={{
        personalDetails,
        setPersonalDetails,
        professionalInformation,
        setProfessionalInformation,
        propertyListings,
        setPropertyListings,
        clientInformation,
        setClientInformation,
        socialDigitalPresence,
        setSocialDigitalPresence,
        additionalAttributes,
        setAdditionalAttributes,
      }}
    >
      {children}
    </AgentDetailsContext.Provider>
  );
};

export const useAgentDetails = () => {
  return useContext(AgentDetailsContext);
};
