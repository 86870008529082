import axios from "axios";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// const base_url="https://apartments-sale.azurewebsites.net http://apartments-env.eba-ver32ppm.us-east-1.elasticbeanstalk.com"
const backendURL = process.env.REACT_APP_BACKEND_URL;

export const registerUser = async (registerData) => {
  const res = await axios
    .post(
      // "http://apartments-env.eba-ver32ppm.us-east-1.elasticbeanstalk.com/api/user/signup",
      `${backendURL}/api/user/signup`,
      {
        name: registerData.name,
        email: registerData.email,
        password: registerData.password,
      }
    )
    .catch((err) => {
      if (err.response.status === 409) {
        console.log("email exits in db");
        toast.error("Email Already Exist", {
          position: "top-center",
          theme: "colored",
        });
      } else {
        console.log(err);
        return console.log("Error Occured No registration");
      }
    });

  if (res.status !== 200 && res.status !== 201) {
    return console.log("Some Error Occured No registration");
  }
  toast.error("Verification Link sent to Email", {
    position: "top-center",
    theme: "colored",
  });
  console.log(res);
  console.log("Result");
  if (res) {
    const data = await res.data;
    return data;
  }
};

export const googleLogin = async (tokenResponse) => {
  try {
    const data = await axios.get(
      "https://www.googleapis.com/oauth2/v3/userinfo",
      {
        headers: {
          Authorization: `Bearer ${tokenResponse.access_token}`,
        },
      }
    );
    console.log("below data");
    console.log(data.data.name);
    console.log(data.data);
    return data.data;
  } catch (err) {
    console.log(err);
    toast.error("Something went Wrong", {
      position: "top-center",
      theme: "colored",
    });
  }

  // const res=await axios.post("http://localhost:8000/user/signup",{
  //    name:registerData.name,
  //    email:registerData.email,
  //    password:"registerDatapassword"})
  //    .catch(err=> console.log("Error occured Not registerd"));

  // if(res.status!==200 && res.status!==201 ){
  //  return console.log("Some Error Occured No registration");
  // }
  // const data= await res.data;
  // return data;
};

export const registerGoogleUser = async (username, useremail) => {
  console.log("Googgle login here");
  const res = await axios
    .post(
      // "http://apartments-env.eba-ver32ppm.us-east-1.elasticbeanstalk.com/api/user/googleLogin",
      `${backendURL}/api/user/googleLogin`,
      {
        name: username,
        email: useremail,
        password: "googleLoginUserAtApartmentSite",
      }
    )
    .catch((err) => {
      console.log("Error occured Not registerd");
      toast.error("Something went Wrong", {
        position: "top-center",
        theme: "colored",
      });
    });

  if (res.status !== 200 && res.status !== 201) {
    return console.log("Some Error Occured No registration");
  }
  const data = await res.data;
  return data;
};

export const loginUser = async (loginData) => {
  const res = await axios
    .post(
      // "http://apartments-env.eba-ver32ppm.us-east-1.elasticbeanstalk.com/api/user/login",
      `${backendURL}/api/user/login`,
      {
        email: loginData.email,
        password: loginData.password,
      }
    )
    .catch((err) => {
      if (err.response.status === 404) {
        console.log("Email doesn't Exist");
        toast.error("User Does Not Exist", {
          position: "top-center",
          theme: "colored",
        });
      } else if (err.response.status === 400) {
        toast.error("Password does not matched", {
          position: "top-center",
          theme: "colored",
        });
      } else if (err.response.status === 409) {
        toast.error(
          "Email Not Verified...Verify Email or Please Register again",
          {
            position: "top-center",
            theme: "colored",
          }
        );
      } else {
        console.log("Error Occured No Login");
      }
    });

  if (res.status !== 200 && res.status !== 201) {
    toast.error("Something went Wrong", {
      position: "top-center",
      theme: "colored",
    });
    return console.log("Login Not done");
  }
  console.log("insecurre");
  if (res) {
    const data = await res.data;
    return data;
  }
};

export const sendForgotlink = async (email) => {
  const res = await axios
    .post(
      // "http://apartments-env.eba-ver32ppm.us-east-1.elasticbeanstalk.com/api/user/forgotPass",
      `${backendURL}/api/user/forgotPass`,
      {
        email: email,
      }
    )
    .catch((err) => {
      if (err.response.status === 409) {
        console.log("email exits in db");
        toast.error("Email Already Exist", {
          position: "top-center",
          theme: "colored",
        });
      } else {
        return console.log("Error Occured No registration");
      }
    });

  if (res.status !== 200 && res.status !== 201) {
    return console.log("Some Error Occured No registration");
  }

  if (res) {
    const data = await res.data;
    return data;
  }
};
export const setPass = async (password, token) => {
  console.log("pass help");
  console.log(token);
  const res = await axios
    .post(
      // `http://apartments-env.eba-ver32ppm.us-east-1.elasticbeanstalk.com/api/user/setNewPass/${token}`,
      `${backendURL}/api/user/setNewPass/${token}`,
      {
        password: password,
      }
    )
    .catch((err) => {
      if (err.response.status === 409) {
        console.log("email exits in db  setPass");
        toast.error("Email Already Exist setPass", {
          position: "top-center",
          theme: "colored",
        });
      } else {
        return console.log("Error Occured No registration setPass");
      }
    });

  if (res.status !== 200 && res.status !== 201) {
    return console.log("Some Error Occured No registration setPass");
  }

  if (res) {
    const data = await res.data;
    return data;
  }
};

export const deleteUser = async (token, userID) => {
  const res = await axios
    .delete(
      // `http://apartments-env.eba-ver32ppm.us-east-1.elasticbeanstalk.com/api/user/delete-user/${userID}`,
      `${backendURL}/api/user/delete-user/${userID}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .catch((err) => {
      if (err.response.status === 409) {
        console.log("email exits in db  setPass");
        toast.error("Email Already Exist setPass", {
          position: "top-center",
          theme: "colored",
        });
      } else {
        return console.log("Error Occured No registration setPass");
      }
    });

  if (res.status !== 200 && res.status !== 201) {
    return console.log("Some Error Occured No registration setPass");
  }

  if (res) {
    const data = await res.data;
    return data;
  }
};

export const updateUserByID = async (token,user) => {
  const body = {
    id:user.id,
    location:user.location,
    priceLow:user.priceLow,
    priceHigh:user.priceHigh
  }
  const res = await axios
    .put(
      // "http://apartments-env.eba-ver32ppm.us-east-1.elasticbeanstalk.com/api/apartment/updateAppartment",
      `${backendURL}/api/user/updateuser`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .catch((err) => {
      console.log(err);
      console.log("updated user did not got");
      toast.error("Something Went Wrong getby Id", {
        position: "top-center",
        theme: "colored",
      });
    });

  if (res.status !== 200 && res.status !== 201) {
    return console.log("error occured updated user not received");
  }
  console.log("updated user succesfully");
  console.log(res);

  const data = await res.data;
  return data;
};

//  export const getSearches = async ()=>{

//     const {user} = useAuthContext();

//     const res=await axios.get("http://localhost:8000/user/searchlist",
//       {headers :  {
//         "Authorization": `Bearer ${user.token}`
//      } })
//        .catch(err=> console.log("Error occured "));

//     if(res.status!==200 && res.status!==201 ){
//      return console.log("success");
//     }

//     const data= await res.data;
//     return data;
//  }
